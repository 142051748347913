import React from 'react';

import _ from 'lodash';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink, Observable} from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory';
import appResolv from '../resolvers/app';
import appSchem from '../schema/app';
import transporterResolv from '../resolvers/transporter';
import transporterSchem from '../schema/transporter';
import customerSchem from '../schema/customer';
import customerResolv from '../resolvers/customer';
import authSchem from '../schema/auth';
import authResolv from '../resolvers/auth';
import dashboardSchem from '../schema/dashboard';
import dashboardResolv from '../resolvers/dashboard';
import { persistCache } from 'apollo-cache-persist';
import { Auth } from '@aws-amplify/auth';
import { onError } from 'apollo-link-error';
import config from '../config';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-boost';
import { RetryLink } from 'apollo-link-retry';
import defaults from '../defaultState';
import DebounceLink from 'apollo-link-debounce';
// import AWS from 'aws-sdk';

const DEFAULT_DEBOUNCE_TIMEOUT = 1000;

const cache = new InMemoryCache({
    dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}`: null),
    cacheRedirects: {
        Query: {
            // If every item in the cache exists, then this is a cache hit...if the docs are right...
            resolve_entity_ids: (_, args, { getCacheKey }) => {
                return args.ids.map((id) => getCacheKey({ __typename: args.typename, _id: id }));
            }
        }
    }
  });

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.map(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

const contractsResolv = {
    Mutation: {
        contracts: (_, {payload: contracts}, {cache}) => {
            cache.writeData({data: {contracts}})
            return contracts
        }
    }
}

const organizationResolv = {
    Mutation: {
        organization: (_, {payload}, {cache}) => {
            const organization = {
                _id: payload._id,
                name: payload.name,
                classType: payload.classType,
                __typename: 'Organization'
            };
            const data = {
                organization
            }
            cache.writeData({data})
            return organization
        }
    }
}

// const stateLink = withClientState({
//   cache,
//   defaults,
//   resolvers: _.merge(appResolv, transporterResolv, contractsResolv, organizationResolv, customerResolv, authResolv, dashboardResolv),
//   typeDefs: _.concat(appSchem, transporterSchem, customerSchem, authSchem, dashboardSchem)
// });

// const stateLink = createLinkWithCache(cache => withClientState({
//     cache,
//     defaults,
//     resolvers: _.merge(appResolv, transporterResolv, contractsResolv, organizationResolv, customerResolv, authResolv),
//     typeDefs: _.concat(appSchem, transporterSchem, customerSchem, authSchem)
// }));

// const appSyncLink = createAppSyncLink({
//   ...config.appSync,
//   auth: {
//     ...config.appSync.auth,
//     jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
//   }
// });

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
          console.log('[Graphql Error]', err);
        switch (err.errorType) {
          case 'UnauthorizedException':
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver

            // modify the operation context with a new token
            return new Observable(async observer => {
                try{
                    // const creds = await Auth.currentUserCredentials();
                    // creds.refresh(err => {throw Error(err)});
                    const idToken = await Auth.currentSession().idToken;
                    console.log('currentSession', await Auth.currentSession());
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                        headers: {
                            ...oldHeaders,
                            authorization: idToken.jwtToken,
                        },
                    });
                    const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    }
                    forward(operation).subscribe(subscriber);
                }catch(error){
                    console.error('ID Token refresh failed', err)
                    observer.error(error)
                }
            })
          default:
            break;
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
);

const authLink = setContext((request) => new Promise( (resolve, reject) => {
    let promises = [];

    promises.push(
    Auth.currentSession()
    .then(session => {
      const token = session.idToken.jwtToken;
      return {
        headers: { Authorization: token }
      }
    })
    )

    // promises.push(
    //     Auth.currentUserCredentials().then((cred) => {
    //         AWS.config.credentials = cred;
    //     })
    // )

    Promise.all(promises)
        .then((values) => resolve(values[0]))
        .catch((err) => reject(err))

  }));

persistCache({
    cache,
    storage: window.localStorage
})

// const client = new AWSAppsyncClient({
//     disableOffline: true
// }, { link });

const client = new ApolloClient({
    cache,
    resolvers: _.merge(appResolv, transporterResolv, contractsResolv, organizationResolv, customerResolv, authResolv, dashboardResolv),
    typeDefs: _.concat(appSchem, transporterSchem, customerSchem, authSchem, dashboardSchem),
    link: ApolloLink.from([
        errorLink,
        new RetryLink(),
        authLink,
        new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
        new HttpLink({
            uri: config.appSync.url
        })
    ])
})

cache.writeData({ data: defaults });

// client.onResetStore(stateLink.writeDefaults)

client.onResetStore(() => cache.writeData({ data: defaults }));

// const withApolloProvider = (Component) => {
//     return props => (
//         <ApolloProvider client={client}>
//             <Rehydrated>
//                 <Component {...props} />
//             </Rehydrated>
//         </ApolloProvider>
//     )
// }

const withApolloProvider = (Component) => {
    return props => (
        <ApolloProvider client={client}>
                <Component {...props} />
        </ApolloProvider>
    )
}

export default withApolloProvider