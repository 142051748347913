
export enum LocationType {
    ONSHORE='ONSHORE',
    OFFSHORE='OFFSHORE'
}

export interface LocationName {
    _id: string,
    name: string
}

export interface Location extends LocationName {
    type: LocationType,
    block: string,
    field: string
}