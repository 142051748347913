
import gql from "graphql-tag";
import {LocationFragment} from "./Location";

export const ScheduledPaxNodeFragment = gql`
fragment ScheduledPaxNodeFragment on PaxNode {
    _id
    _rev
    lastName
    firstName
    transitType
    scheduledOrder
    scheduledGroup
    classType
    paxWeight
    bagWeight
    bagCount
    chargeCode
    notesForPilot
    personID {
        _id
        dob
        nation
        extraBroadState
        credentials {
            _id
            key
            type
            expiration
        }
        contactInfo {
            phone {
                number
                type
            }
        }
        lastPaxWeight
        lastBagWeight
        lastBagCount
    }
    customerID {
        _id
        name
    }
    tpID {
        _id
        name
    }
    employerID {
        _id
        name
    }
    departureID {
        ...LocationFragment
    }
    destinationID {
        ...LocationFragment
    }
    lastKnownController {
        ...LocationFragment
    }
    currentCarrierID {
        _id
        desig
    }
    authorization {
        onWhiteList
        brdRestriction_Isn {
            status
            type
            notes
            reason
        }
    }
    isnCheckError {
        message
    }
    lastIsnResultWithHeader {
        isn_customer
        isn_ownerid
        success
        error_message
        quick_check_status
        reason
        qc_count
        qc_result {
            isnStatus {
                shortLabel
            }
            isnId
            lastName
            firstName
            companyID
            companyName
            trainingStatus
            mostRecentTrainingQualificationExpirationName
            mostRecentTrainingExpirationDate
            assignedActivities
            quickCheckStatus
            reasonsForNonCompliance
            trainingDeficiencies
            reason
        }
        selectedResult {
            isnStatus {
                shortLabel
            }
            isnId
            lastName
            firstName
            companyID
            companyName
            trainingStatus
            mostRecentTrainingQualificationExpirationName
            mostRecentTrainingExpirationDate
            assignedActivities
            quickCheckStatus
            reasonsForNonCompliance
            trainingDeficiencies
            reason
        }
    }
}
${LocationFragment}
`

export const ScheduledCgoNodeFragment = gql`
fragment ScheduledCgoNodeFragment on CargoNode {
    _id
    _rev
    name
    classType
    transitType
    scheduledOrder
    scheduledGroup
    weight
    hazmat
    hazmatUnCode
    departureID {
        ...LocationFragment
    }
    lastKnownController {
        ...LocationFragment
    }
    destinationID {
        ...LocationFragment
    }
    currentCarrierID {
        _id
        desig
    }
    attentionTo
    initials
    notes
    notesForPilot
    customerID {
        _id
        name
    }
    tpID {
        _id
        name
    }
}
${LocationFragment}
`

export const ScheduledFlightFragment = gql`
fragment ScheduledFlightFragment on FlightNode {
    _id
    desig
    scheduledFlightDate
    callSign
    lastKnownController {
        ...LocationFragment
    }
    paxIDList {
        ...ScheduledPaxNodeFragment
    }
    cgoIDList {
        ...ScheduledCgoNodeFragment
    }
    pilot {
        _id
        name {
            lastName
            firstName
        }
    }
    copilot {
        _id
        name {
            lastName
            firstName
        }
    }
    legs
    legsArray {
        departure
        destination
        departureID
        destinationID
        bagCount
        bagWeight
        cgoCount
        cgoWeight
        order
        paxWeight
        paxCount
        cgoIDs{
            ...ScheduledCgoNodeFragment
        }
        paxIDs {
            ...ScheduledPaxNodeFragment
        }
    }
    tpID {
        _id
        name
    }
    customerID {
        _id
        name
    }
    contract {
        _id
        name
    }
    aircraftID {
        _id
        tailNum
    }
    departureID {
        ...LocationFragment
    }
}
${ScheduledPaxNodeFragment}
${ScheduledCgoNodeFragment}
${LocationFragment}
`

export const ScheduledPaxCgoGroupV2Fragment = gql`
fragment ScheduledPaxCgoGroupV2Fragment on ScheduledPaxCgoGroupV2 {
    scheduledFlightDate
    scheduledGroup
    lastKnownController {
        _id
        name
    }
    paxOutbound
    paxTransfer
    paxInbound
    cgoOutbound
    cgoTransfer
    cgoInbound
    paxOutboundOnFlight
    paxTransferOnFlight
    paxInboundOnFlight
    cgoOutboundOnFlight
    cgoTransferOnFlight
    cgoInboundOnFlight
    legCount
    legs {
        departureID
        destinationID
        departureDoc {
            ...LocationFragment
        }
        destinationDoc {
            ...LocationFragment
        }
        scheduledOrder
        paxnodeIDs
        cgonodeIDs
        paxnodeDocs {
            ...ScheduledPaxNodeFragment
        }
        cgonodeDocs {
            ...ScheduledCgoNodeFragment
        }
        totalWeight
        totalPaxWeight
        totalCgoWeight
        totalBagWeight
        totalBagCount
    }
}
${ScheduledPaxNodeFragment}
${ScheduledCgoNodeFragment}
${LocationFragment}
`

export const ResolveScheduledPaxNodeIdsQuery = gql`
query ResolveScheduledPaxNodeIds($ids: [ID!]!){
    resolve_entity_ids(ids: $ids, typename: "PaxNode"){
        ...ScheduledPaxNodeFragment
    }
}
${ScheduledPaxNodeFragment}
`

export const ResolveScheduledCgoNodeIdsQuery = gql`
    query ResolveScheduledCgoNodeIds($ids: [ID!]!){
        resolve_entity_ids(ids: $ids, typename: "CargoNode"){
            ...ScheduledCgoNodeFragment
        }
    }
    ${ScheduledCgoNodeFragment}
`